<template>
    <div class="myJournalTickets">
        <b-container fluid>
            <h1>My Journal Tickets</h1>
            <b-row align-h="end">
                <b-col cols="2" class="text-right pb-2">
                    <span style="margin: 4px;">Show</span>
                    <b-form-select
                            v-model="rowsPerPage"
                            :options="perPageOptions"
                            size="sm"
                            class="select-rows"
                    ></b-form-select>
                    <span style="margin: 4px;">results</span>
                </b-col>
            </b-row>
            <b-table 
                id="myjournaltickets-table"
                table-class="my-requests-table"
                striped 
                bordered 
                hover 
                :items="items" 
                :fields="fields"
                ref="selectableTable" 
                selectable 
                select-mode="single" 
                selected-variant="active" 
                @row-selected="onRowSelected" 
                responsive="sm" 
                :current-page="currentPage"
                :per-page="rowsPerPage"
                :busy="isBusy"
            >
            <template v-slot:table-busy>
                <div class="text-center my-2 table-loading" >
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                </div>
            </template>
            <!--<template v-slot:table-busy>
                <div class="text-center my-queue-spinner-container">
                    <b-spinner class="ml-auto"></b-spinner>
                </div>
            </template>  -->  
            
            </b-table>
            <div class="error-loading" v-if="errorLoading">
                <div class="text-center table-error text-danger">There was an error loading the data in the table. Please refresh the page.</div>
            </div>
            <b-pagination
                pills
                class="custom-pagination"
                v-model="currentPage"
                :per-page="rowsPerPage"
                :total-rows="rows"
                first-text="First"
                last-text="Last"
                align="center"
                aria-controls="myjournaltickets-table"
            ></b-pagination>
            <Modal
                :force-open="modalNotifications"
                header="My Queue"
                :content="content"
                :variant="modalVariant"
                @close="onCloseNotifications"
            />          
        </b-container>
    </div>
</template>

<script>
    import Modal from '../Modal'

    export default {
        components: { Modal }, 
        data() {
            return {
                isBusy: false,
                errorLoading: false,
                fields: [
                    /*{
                        key: 'request_id',
                        label: 'Journal Ticket #',
                        sortable: true,
                    },*/
                    {
                        key: 'jt_number',
                        label: 'Journal Ticket #',
                        sortable: true,
                    },                    {
                        key: 'nonsig_number',
                        label: 'Nonsig #',
                        sortable: true,
                    },
                    {
                        key: 'nonsig_name',
                        label: 'Name',
                        sortable: true
                    },
                    /*{
                        key: 'jt_type_code',
                        label: 'Type',
                        sortable: true
                    },*/
                    {
                        key: 'current_status',
                        label: 'Current Status',
                        sortable: true
                    },
                    {
                        key: 'next_approver',
                        label: 'Next Approver',
                        sortable: true
                    },
                    {
                        key: 'invoice_document',
                        label: 'Invoice Document',
                        sortable: true
                    },
                    {
                        key: 'payment_type',
                        label: 'Payment Type',
                        sortable: true
                    },
                    {
                        key: 'payment',
                        label: 'Payment Amount',
                        sortable: true,
                    },
                    {
                        key: 'requestor_name',
                        label: 'Submitted By',
                        sortable: true,
                    },                    
                    {
                        key: 'date_submitted',
                        label: 'Date Submitted',
                        sortable: true,
                    }
                ],
                items: [],
                selected: [],
                info: {},
                currentPage: 1,
                rowsPerPage: 10,
                perPageOptions: [10, 25, 50, 100],
                rows: 0,
                modalNotifications: false,
                content: '',
                modalVariant: 'success',
            }
        },
        computed: {
            userSession() {
                return this.$store.getters['auth/userSession'];
            },
        },
        methods: {
            onRowSelected(items) {
                this.selected = items
                let id = _.get(items, '0.request_id')
                if (id) {
                  //this.$router.push({path: '/new-journal-ticket', params: {id} });
                  this.$router.push({path: '/new-journal-ticket', query: {id} });
                }
                //this.getRequestInfo(id);
            },
            getList() {
                this.isBusy = true;
                this.$store
                    .dispatch('tickets/getList', { filters: { role: this.$store.state.auth.userJTRoleType, request_type: 'JT' } })
                    .then(({ list, total }) => {
                        this.items = list;
                        this.rows = total;
                        this.isBusy = false;
                    })
                    .catch((error) => {
                        //this.errorLoading = true;
                        console.error(error)
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                        this.isBusy = false;
                     }
                    );
            },
            onCloseNotifications() {
                this.modalNotifications = false;
            },
        },
        mounted: function () {
            this.getList();
        }
  }
</script>

<style scoped>
    .myJournalTickets {
        margin-bottom: 60px;
    }
    .myJournalTickets .container-fluid {
        min-height: 500px;
    }
    .select-rows {
        max-width: 75px;
    }
    .my-queue-spinner-container {
        margin-bottom: 200px;
    }
    .table-error {
        font-family: 'Barlow', sans-serif;
        font-style: normal;
        font-weight: 700;
    }
    .table-loading {
        color: #274ba7;
    }
</style>