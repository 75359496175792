<template>
    <div class="uploadJTTemplate">
        <b-container>
            <h1>Upload Journal Ticket Template</h1>
            <b-form @submit.prevent="onSubmit">
                <div>
                    <b-form-group id="input-group-template" label="Attach Template" label-for="template-input"
                        :class="{ 'form-group--error': $v.template.$error }">
                        <div class="small">
                            <em>A maximum of 100 records per template is allowed</em>
                        </div>
                        <b-form-file id="template-input" v-model="$v.template.$model" ref="template"
                            placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                            accept=".xls, .xlsx, .xlsb"></b-form-file>
                        <div class="error small validation-error"
                            v-if="$v.template.$error && !$v.template.required && !templateExtensionError">
                            Template file is required
                        </div>
                        <div class="error small validation-error" v-if="templateExtensionError">
                            Template file must have .xlsx or .xls extension
                        </div>
                    </b-form-group>

                    <b-form-group id="input-group-documentation" label="Attach Supporting Documentation (optional)"
                        label-for="supporting-documentation">
                        <div class="small">
                            <em>
                                To upload more than 1 file, select both files together at the same time while browsing
                            </em>
                        </div>
                        <b-form-file v-model="documentation" ref="documentation-input"
                            placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                            multiple></b-form-file>
                        <div v-if="documentation && documentation.length > 0">
                            <a @click="clearDocumentations" class="clear-documentations" tabindex="0">
                                <font-awesome-icon icon="minus-circle" /> Remove attachment(s)
                            </a>
                        </div>
                    </b-form-group>
                </div>

                <div class="btn-actions-container">
                    <b-button type="submit" class="btn-yellow">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </b-button>
                </div>
            </b-form>

            <!-- Notifications modal -->
            <Modal :force-open="modalNotifications" header="Upload Template" :content="content" :variant="modalVariant"
                @close="onCloseNotifications" />
        </b-container>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Modal from "../Modal";
import { isTemplateFileValid } from '../../lib/services/templates';

export default {
    components: { Modal },
    data() {
        return {
            template: null,
            documentation: null,
            loading: false,
            modalNotifications: false,
            content: "",
            modalVariant: "success",
            templateExtensionError: false
        };
    },
    validations: {
        template: { required },
    },
    watch: {
        template: function (newValue) {
            if (newValue) {
                const extension = newValue.name.split(".").pop();
                if (extension !== "xlsx" && extension !== "xlsb" && extension !== "xls") {
                    this.template = null;
                    this.$refs['template'].reset();
                    this.templateExtensionError = true;
                    return;
                }

                this.templateExtensionError = false;
            }
        },
    },
    computed: {
        userSession() {
            return this.$store.getters['auth/userSession'];
        }
    },
    methods: {
        //as a requestor, when the form submits
        onSubmit(evt) {
            //check for validation errors
            this.$v.$touch();
            //if validation fails
            if (this.$v.$invalid) {
                //hide spinner
                this.loading = false;
            } else {
                //show spinner
                this.loading = true;

                // TODO API call
                this.submitTemplate()
                    .then(data => {
                        this.content = data.message;
                        this.modalVariant = 'success';
                        this.modalNotifications = true;
                        this.loading = false;
                        this.template = null;
                        this.documentation = null;
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                        this.loading = false;
                    });
            }
        },
        async submitTemplate() {
            let ms = Date.now();

            if (this.template) {
                let file = this.template;

                const validationResult = await isTemplateFileValid(file, 'JT');

                if (!validationResult.isValid) {
                    this.template = null;
                    this.$refs['template'].reset();
                    return Promise.reject(new Error(validationResult.errorMessage));
                }

                let params = {
                    file: file,
                    filename: 'templates/' + ms + '_' + file.name,
                    filetype: file.type,
                    prefix: 'JT/',
                    metadata: { id: this.userSession.email },
                };
                try {
                    await this.$store.dispatch('storage/putTemplateFile', params);
                } catch (e) {
                    return Promise.reject(e);
                }
            }

            if (this.documentation?.length) {
                let files = this.documentation;

                for (const file of files) {
                    let params = {
                        file: file,
                        filename: 'supporting-docs/' + ms + '/' + file.name,
                        filetype: file.type,
                        prefix: 'JT/'
                    };
                    try {
                        await this.$store.dispatch('storage/putTemplateFile', params);
                    } catch (e) {
                        return Promise.reject(e);
                    }
                }
            }

            return Promise.resolve({ message: 'Upload(s) complete. Template has been queued for batch processing.' })
        },
        onCloseNotifications() {
            this.modalNotifications = false;
        },
        clearDocumentations() {
            this.documentation = null;
        }
    },
};
</script>

<style scoped>
::v-deep .modal-body {
    font-family: "Barlow", sans-serif;
    font-style: normal;
    font-weight: 400;
}

h2 {
    padding: 10px 0;
}

.uploadJTTemplate {
    margin-bottom: 40px;
}

.uploadJTTemplate .container {
    max-width: 800px;
}

.uploadJTTemplate h1 {
    margin: 20px 0;
}

.uploadJTTemplate legend {
    font-weight: 700;
}

.clear-documentations {
    color: #ff2534;
    font-size: 12px;
}

.clear-documentations:hover {
    color: #ff2534;
    cursor: pointer;
    text-decoration: underline;
}

.btn-actions-container {
    margin-top: 20px;
}

.btn-actions-container .btn-yellow {
    margin: 0 10px;
}

.btn-actions-container .btn-yellow:first-child {
    margin: 0;
}

.form-group--error>label {
    color: red;
}

.form-group--error input {
    border: 1px solid red;
}

.form-group--error .error.small {
    color: red;
}

.validation-error {
    color: red;
}
</style>