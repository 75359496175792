<template>
    <div class="Search">
        <b-container>
            <div class="form-filters">
                <h1>Search Rebate Payments</h1>
                <b-card no-body class="mb-1" role="tablist">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                        <b-button block href="#" v-b-toggle.accordion-1>Search Filters</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" visible appear accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-form v-if="show">
                                <b-row>
                                    <b-col>
                                        <b-form-group id="rebate-number-group" label="Rebate Agreement Number:" label-for="rebate-number">
                                            <b-form-input
                                                id="rebate-number"
                                                v-model="form.rebateNumber"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>

                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <label for="rebate-valid-from">Rebate Agreement Valid From Date:</label>
                                            <b-form-datepicker 
                                                id="rebate-valid-from" 
                                                v-model="form.rebateValidFrom"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <label for="rebate-valid-to">Rebate Agreement Valid To Date:</label>
                                            <b-form-datepicker 
                                                id="rebate-valid-to" 
                                                v-model="form.rebateValidTo"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group id="rebate-number-group" label="Common Owner Number" label-for="rebate-common-owner">
                                            <b-form-input
                                                id="rebate-common-owner"
                                                v-model="form.rebateCommonOwner"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group id="rebate-number-group" label="Common Owner Name" label-for="rebate-common-owner-name">
                                            <b-form-input
                                                id="rebate-common-owner-name"
                                                v-model="form.rebateCommonOwnerName"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group id="rebate-number-group" label="Customer Group" label-for="rebate-customer-group">
                                            <b-form-input
                                                id="rebate-customer-group"
                                                v-model="form.rebateCustomerGroup"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group id="rebate-number-group" label="Rebate PBU" label-for="rebate-pbu">
                                            <b-form-input
                                                id="rebate-pbu"
                                                v-model="form.rebatePbu"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <!--<b-col>
                                        <b-form-group label="Status" label-for="rebate-status">
                                            <b-form-select id="rebate-status" :options="form.rebateStatus">
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>-->
                                    <b-col>
                                        <b-form-group label="Status" label-for="rebate-status">
                                            <b-form-select id="rebate-status" v-model="form.rebateStatus">
                                                <option value="">No Selection</option>
                                                <option v-for="st in status" v-bind:key="st.status_code" v-bind:value="st.status_code">{{st.status}}</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Payment Period" label-for="payment-period">
                                            <b-form-select id="payment-period" v-model="form.paymentPeriod">
                                                <option value="">No Selection</option>
                                                <option value="Q1">Q1</option>
                                                <option value="Q2">Q2</option>
                                                <option value="Q3">Q3</option>
                                                <option value="Q4">Q4</option>
                                                <option value="Jan">January</option>
                                                <option value="Feb">February</option>
                                                <option value="Mar">March</option>
                                                <option value="Apr">April</option>
                                                <option value="May">May</option>
                                                <option value="Jun">Jun</option>
                                                <option value="July">July</option>
                                                <option value="Aug">August</option>
                                                <option value="Sept">September</option>
                                                <option value="Oct">October</option>
                                                <option value="Nov">November</option>
                                                <option value="Dec">December</option>
                                                <option value="ANNUAL">ANNUAL</option>
                                                <option value="Other">Other</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group label="Requestor" label-for="rebate-requestor">
                                            <b-form-select id="rebate-requestor" v-model="form.rebateRequestor">
                                                <option value="">No Selection</option>
                                                <option v-for="user in users" v-bind:key="user.user_id" v-bind:value="user.user_id">{{user.user_name}}</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <label for="requestor-submission-from">Requestor Submission From Date:</label>
                                            <b-form-datepicker 
                                                id="requestor-submission-from" 
                                                v-model="form.rebateRequestorFromDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <label for="requestor-submission-to">Requestor Submission To Date:</label>
                                            <b-form-datepicker 
                                                id="requestor-submission-to" 
                                                v-model="form.rebateRequestorToDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <label for="completion-from">Final Completion From Date:</label>
                                            <b-form-datepicker 
                                                id="completion-from" 
                                                v-model="form.rebateFinalCompletionFromDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <label for="completion-to">Final Completion To Date:</label>
                                            <b-form-datepicker 
                                                id="completion-to" 
                                                v-model="form.rebateFinalCompletionToDate"
                                                today-button
                                                reset-button
                                                close-button      
                                                locale="en">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="text-center">
                                        <b-button type="button" class="btn-yellow" v-on:click="search()">Search</b-button>
                                    </b-col>          
                                </b-row>
                            </b-form>                        
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <Modal
                :force-open="modalNotifications"
                header="New Rebate Payment"
                :content="content"
                :variant="modalVariant"
                @close="onCloseNotifications"
            />
        </b-container>
        <br>
        <b-container fluid>
            <b-row>
                    <b-col cols="6" md="2" offset-md="5" class="text-center">
                        <b-button type="button" class="btn-yellow mt-1 mb-3" v-on:click="getItemsExport()">Export</b-button>
                    </b-col>
                    <b-col cols="6" md="3" offset-md="2" class="text-right">
                        <div class="mt-3 mb-2">
                            <span style="margin: 4px;">Show</span>
                            <b-form-select
                                    v-model="rowsPerPage"
                                    :options="perPageOptions"
                                    size="sm"
                                    class="select-rows"
                            ></b-form-select>
                            <span style="margin: 4px;">results</span>
                        </div>
                </b-col>
            </b-row>
            <b-table 
                id="search-table"
                ref="searchTable"
                table-class="search-table"
                striped 
                bordered 
                hover 
                :items="getItems" 
                :fields="fields"
                :per-page="rowsPerPage"
                :current-page="currentPage"
                :filter="filter"
                responsive="sm" 
                @row-clicked="expandAdditionalInfo"
            >
                <template #cell(request_id)="row">
                    <router-link class="request-link" :to="{ name: 'New Rebate Payment', query: { id: row.item.request_id }}">{{row.item.request_id}}</router-link>
                </template>
                <template slot="row-details" slot-scope="row">
                    <p v-for="item in history[row.item.request_id]" :key="item.history_status">{{item.history_status}}</p>
                    <router-link class="request-link" :to="{ name: 'New Rebate Payment', query: { id: row.item.request_id }}">View All Details <font-awesome-icon icon="chevron-circle-right" /></router-link>
                </template>
            </b-table>
            <b-pagination
                pills
                class="custom-pagination"
                v-model="currentPage"
                :per-page="rowsPerPage"
                :total-rows="rows"
                first-text="First"
                last-text="Last"
                align="center"
                aria-controls="search-table"
            ></b-pagination>
        </b-container>
    </div>
</template>

<script>

import { ALL_LIST_LIMIT } from '../../config/constants';
import * as XLSX from 'xlsx/xlsx.mjs';
import Modal from '../Modal'

    export default {
        components: { Modal }, 
        data() {
            return {
                show: [],
                form: {
                    rebateNumber: '',
                    rebateValidFrom: '',
                    rebateValidTo: '',
                    rebateCommonOwner: '',
                    rebateCommonOwnerName: '',
                    rebateCustomerGroup: '',
                    rebatePbu: '',
                    rebateStatus: [
                        { value: '0', text: 'Submitted by Requestor' },
                        { value: '0', text: 'Reviewing by Requestor' },
                        { value: '1', text: 'Reviewing by Approver' },
                        { value: '2', text: 'Approved by Approver' },
                        { value: '3', text: 'Denied by Approver' },
                        { value: '3', text: 'Reviewing by GBS Support & Control' },
                        { value: '3', text: 'Approved by GBS Support & Control' },
                        { value: '3', text: 'Denied by GBS Support & Control' },
                    ],
                    rebateRequestorSubission: '',
                    rebateRequestorFromDate: '',
                    rebateRequestorToDate: '',
                    rebateFinalCompletionFromDate: '',
                    rebateFinalCompletionToDate: '',
                    paymentPeriod: null,
                },
                fields: [
                    {
                        key: 'request_id',
                        label: 'Request ID',
                        sortable: true
                    },
                    {
                        key: 'agreement_no',
                        label: 'Rebate Agreement #',
                        sortable: true
                    },
                    {
                        key: 'common_owner_number',
                        label: 'Common Owner/Nonsig #',
                        sortable: true
                    },
                    {
                        key: 'common_owner_name',
                        label: 'Name',
                        sortable: true
                    },
                    {
                        key: 'pbu_name',
                        label: 'PBU',
                        sortable: true
                    },
                    {
                        key: 'current_status',
                        label: 'Current Status',
                        sortable: true
                    },
                    {
                        key: 'next_approver',
                        label: 'Next Approver',
                        sortable: true
                    },
                    {
                        key: 'invoice_document',
                        label: 'Invoice Document',
                        sortable: true
                    },
                    {
                        key: 'payment_type',
                        label: 'Payment Type',
                        sortable: true
                    },
                    {
                        key: 'payment_amount',
                        label: 'Payment Amount',
                        sortable: true,
                    },
                    {
                        key: 'payment_period',
                        label: 'Payment Period',
                        sortable: true,
                    },
                    {
                        key: 'request_date',
                        label: 'Date Submitted',
                        sortable: true,
                    }
                ],
                items: [],
                exportItems: [],
                selected: [],
                info: {},
                status: [],
                users: [],
                history: {},
                rowsPerPage: 10,
                perPageOptions: [10, 25, 50, 100],
                currentPage: 1,
                rows: 0,
                filter: '',
                first: true,
                modalNotifications: false,
                content: '',
                modalVariant: 'success',
                request_type: 'RBT',
            }
        },
        methods: {
            search(){
                this.$refs.searchTable.refresh();
            },
            getItems(ctx) {
                if (this.first) {
                    this.first = false;
                    return []
                }
                const params = this.getSearchParams();
                let sortParams = {};
                if (ctx.sortBy && typeof ctx.sortDesc === 'boolean') {
                    sortParams = {
                        [ctx.sortBy]: ctx.sortDesc ? 'DESC' : 'ASC' 
                    }
                }
                const promise = 
                    this.$store
                        .dispatch('reports/getList', { filters: params, rowsPerPage: ctx.perPage, page: ctx.currentPage, sort: sortParams });
                return promise.then(({ list, total }) => {
                        this.items = list;
                        this.items.forEach((item) => {
                            this.$set(item, '_showDetails', false)
                        }); 
                        this.rows = total;
                        return(this.items);
                })
                .catch(error => {
                    console.error(error);
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
                    return []  
                });
            },
            getItemsExport() {
                const params = this.getSearchParams();
                this.$store
                    .dispatch('reports/getExportList', { filters: params })
                    .then(({ list, total }) => {
                        const data = XLSX.utils.json_to_sheet(list)
                        const wb = XLSX.utils.book_new()
                        XLSX.utils.book_append_sheet(wb, data, 'data')
                        XLSX.writeFile(wb,'rebate.xlsx')
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });
            },
            getList() {
                const params = this.getSearchParams();
                this.$store
                    .dispatch('reports/getList', { filters: params })
                    .then(({ list, total }) => {
                        this.items = list;
                        this.items.forEach((item) => {
                            this.$set(item, '_showDetails', false)
                        }); 
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true; 
                    });                           
            },
            getSearchParams() {
                return {
                    _showDetails: false,
                    request_type: this.request_type,
                    agreement_no: this.form.rebateNumber,
                    valid_from_date: this.form.rebateValidFrom,
                    valid_to_date: this.form.rebateValidTo,
                    common_owner_number: this.form.rebateCommonOwner,
                    common_owner_name: this.form.rebateCommonOwnerName,
                    customer_group: this.form.rebateCustomerGroup,
                    pbu_name: this.form.rebatePbu,
                    request_status: this.form.rebateStatus,
                    requestor: this.form.rebateRequestor,
                    request_from_date: this.form.rebateRequestorFromDate,
                    request_to_date: this.form.rebateRequestorToDate,
                    completion_from_date: this.form.rebateFinalCompletionFromDate,
                    completion_to_date: this.form.rebateFinalCompletionToDate,
                    payment_period: this.form.paymentPeriod,
                }
            },
            /*getReportInfo(id) {
                this.$store
                    .dispatch('reports/getInfo', { id })
                    .then(info => {
                        this.info = info;
                        alert(JSON.stringify(this.info));
                    })
                    .catch(error => console.error(`Can't get rebate data with id: ${id}, error: ${JSON.stringify(error)}`));
            },*/
            getStatusList() {
                //alert('in getUserList');
                this.$store
                    .dispatch('reports/getStatusList', { filters: { request_type: this.request_type } })
                    .then(({ list, total }) => {
                        let listSorted = list.sort();
                        this.status = listSorted;
                        //alert(JSON.stringify(this.status));
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });
                },
            getUserList() {
                //alert('in getUserList');
                this.$store
                    .dispatch('reports/getUserList', { filters: { request_type: this.request_type } })
                    .then(({ list, total }) => {
                        let listSorted = list.sort();
                        this.users = listSorted;
                        //alert(JSON.stringify(this.users));
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });                           
            },
            getHistoryInfo(id) {
                this.$store
                    .dispatch('reports/getHistoryInfo', { id })
                    .then(info => {
                        // To show all activity chain
                        this.$set(this.history, id, info);

                        // To show only last activity details
                        //this.$set(this.history, id, [info[info.length - 1]]);
                    })
                    .catch(error => {
                        console.error(error);
                        this.content = error.message;
                        this.modalVariant = 'error';
                        this.modalNotifications = true;
                    });                            
            },
            expandAdditionalInfo(row) {
                row._showDetails = !row._showDetails;
                if (row._showDetails && this.history[row.request_id] === undefined) {
                    this.getHistoryInfo(row.request_id);
                }
            },
            onCloseNotifications() {
                this.modalNotifications = false;
            },
        },
        mounted: function () {
            this.getUserList();
            this.getStatusList();
        }
  }
</script>

<style scoped>
    .Search {
        margin-bottom: 60px;
    }

    .Search .container-fluid {
        min-height: 500px;
    }

    .form-filters {
        max-width: 780px;
        margin: 0 auto;
    }

    .btn-block {
        background-color: #737b8a;
        font-family: "Barlow", sans-serif;
        font-style: normal;
        font-weight: 700;
    }

    .btn-block a {
        color: #fff;
    }

    .card-body {
        background-color: #dbdfe4;
    }

    .table-hover tbody tr:hover {
        cursor: pointer;
    }

    .table-hover tbody tr.b-table-details:hover {
        cursor: unset;
    }

    /*select # of rows dropdown*/
    .select-rows {
        max-width: 75px;
    }
    thead tr {
        background-color: #6c757d;
        color: #fff;
        font-family: "Barlow", sans-serif;
        font-style: normal;
        font-weight: 700;
    }

    
</style>